var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
        [
          _c("c-table", {
            ref: "table",
            attrs: {
              title: "유형별 사진 관리 목록",
              filtering: false,
              isExcelDown: false,
              tableId: "hazardEquipmentTable",
              columns: _vm.grid.columns,
              data: _vm.grid.data,
            },
          }),
          _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }