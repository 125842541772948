<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <!-- 유형별 사진 관리 목록 -->
        <c-table
          ref="table"
          title="유형별 사진 관리 목록"
          :filtering="false"
          :isExcelDown="false"
          tableId="hazardEquipmentTable"
          :columns="grid.columns"
          :data="grid.data"
        >
        </c-table>
        <c-dialog :param="popupOptions"></c-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'hazard-equipment-type-picture',
  data() {
    return {
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      grid: {
        columns: [
          {
            name: 'codeName',
            field: 'codeName',
            label: '기계분류',
            align: 'left',
            style: 'width:400px',
            sortable: false,
          },
          {
            name: 'attachFile',
            field: 'attachFile',
            label: '관련 사진',
            align: 'center',
            style: 'width:400px',
            type: 'attach',
            taskClassCd: 'HAZARD_EQUIPMENT_PICTURE',
            keyText: 'code',
            sortable: false,
          },
        ],
        data: [],
      },
      editable: true,
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      // api scope
      this.listUrl = selectConfig.sys.code.mst.list.url;
      this.getList();
    },
    getList() {
      this.$http.url = this.$format(this.listUrl, 'HHM_TYPE_CD');
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        console.log(_result.data)
        this.grid.data = _result.data;
      },
      () => {
      });
    },
  }
};
</script>
